import React, { FC, ReactNode } from 'react';

interface Props {
    isAllowed: boolean;
    children: ReactNode;
    fallback?: ReactNode;
}

export const ProtectedComponent: FC<Props> = React.memo(({ isAllowed, children, fallback }) => {
    if (!isAllowed) return <>{fallback ?? null}</>;

    return <>{children}</>;
});
