import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from './app-layout';
import { AdminRoute } from './auth/admin-route';
import { useAuth } from './auth/auth-provider';
import { Callback } from './auth/callback';
import { LoggedOut } from './auth/logged-out';
import { PrivateRoute } from './auth/private-route';
import { localized } from './i18n/i18n';
import { routes } from './state/routes';
import { useCheckIfMobile } from './utilities/use-check-if-mobile';
import { Dealers } from './view/pages/dealers/dealers';
import { Devices } from './view/pages/devices/devices';
import { ProductionTests } from './view/pages/devices/production-tests';
import { ServiceHistory } from './view/pages/devices/service-history';
import { SnapshotLog } from './view/pages/devices/snapshot-log';
import { Facilities } from './view/pages/facilities/facilities';
import { Users } from './view/pages/users/users';
import { DeviceErrors } from './view/pages/devices/device-errors';
import { ProtectedComponent } from './auth/protected-component';
import { useSelector } from 'react-redux';
import { selectCurrentUserPermissions } from './state/features/users/users-slice';
import { NoPermissionPage } from './auth/no-permission-page';

export const useLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileOnly: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        desktopOnly: {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        appContent: { flex: 1 },
    }),
);

export const AppRoutes: FC = React.memo(() => {
    const classes = useLayoutStyles();
    const { isAuthenticated, logout, isGlobalAdmin } = useAuth();
    const currentUserPermissions = useSelector(selectCurrentUserPermissions);
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useCheckIfMobile();

    useEffect(() => {
        if (isMobile) {
            alert(localized('MobileDevice'));
        } else {
            if (location.pathname === '/' && isAuthenticated) {
                // VERY IMPORTANT TO NOT NAVIGATE BEFORE USER IS AUTHENTICATED
                navigate(routes.devices);
            }
        }
    }, [location.pathname, navigate, isAuthenticated, isMobile, logout]);

    // Don't render any components before auth has been initialized
    if (isAuthenticated === undefined) {
        return null;
    }

    return (
        <Routes>
            <Route path="/" element={<AppLayout />}>
                <Route path="/" element={<PrivateRoute />}>
                    <Route element={<AdminRoute />}>
                        <Route path={routes.userAdministration} element={<Users />} />
                    </Route>
                    <Route path={`${routes.facilities}/:dealerId`} element={<Facilities />} />
                    <Route path={routes.dealers} element={<Dealers />} />
                    <Route path={routes.devices} element={<Devices />} />
                    <Route path={routes.devicesServiceHistoryWithSerial} element={<ServiceHistory />} />
                    <Route path={routes.devicesSnapshotLogWithSerial} element={<SnapshotLog />} />
                    <Route path={`${routes.deviceErrors}/:serialNumber`} element={<DeviceErrors />} />
                    <Route
                        path={`${routes.devicesProductionTests}/:serialNumber`}
                        element={
                            <ProtectedComponent
                                isAllowed={isGlobalAdmin || currentUserPermissions.canUseProductionTool}
                                fallback={<NoPermissionPage />}>
                                <ProductionTests />
                            </ProtectedComponent>
                        }
                    />
                </Route>
            </Route>
            <Route path={routes.loggedOut} element={<LoggedOut />} />
            <Route path={routes.callback} element={<Callback />} />
        </Routes>
    );
});
