import React, { FC, useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { localized } from '../../../../i18n/i18n';
import { FlexRow } from '../../default-components/flex-row';
import { CurrentGraph } from './current-graph';
import { FlexColumn } from '../../default-components/flex-column';
import { VoltageGraph } from './voltage-graph';
import { clearDisplayedProductionTestRuns } from '../../../../state/features/production-test/production-tests-slice';
import { useDispatch } from 'react-redux';
import { TestResults } from './test-results';

export const ProductionTestGraph: FC = React.memo(() => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(clearDisplayedProductionTestRuns());
        };
    }, []);

    return (
        <>
            <FlexRow marginTop={'22px'}>
                <Tabs onChange={handleTabChange} value={tab}>
                    <Tab {...a11yProps(0)} label={localized('CurrentTab')} />
                    <Tab {...a11yProps(1)} label={localized('Voltage')} />
                    <Tab {...a11yProps(2)} label={localized('TestResults')} />
                </Tabs>
            </FlexRow>
            <TabPanel index={0} activeIndex={tab}>
                <CurrentGraph />
            </TabPanel>
            <TabPanel index={1} activeIndex={tab}>
                <VoltageGraph />
            </TabPanel>
            <TabPanel index={2} activeIndex={tab}>
                <TestResults />
            </TabPanel>
        </>
    );
});

interface TapPanelProps {
    index: number;
    activeIndex: number;
}

const TabPanel: FC<TapPanelProps> = React.memo((props) => {
    const { children, activeIndex, index, ...other } = props;

    return activeIndex === index ? (
        <FlexColumn
            height={'100%'}
            role="tabpanel"
            hidden={activeIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {children}
        </FlexColumn>
    ) : null;
});

// Taken from MUI tabs page: https://mui.com/material-ui/react-tabs/#basic-tabs
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
