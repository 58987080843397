import React, { FC } from 'react';
import { Checkbox, Paper, Stack, Typography } from '@mui/material';
import { backgroundColorPaper } from '../../../../styles/color-constants';
import { useSelector } from 'react-redux';
import { selectDisplayedProductionTestRuns } from '../../../../state/features/production-test/production-tests-slice';
import { localized } from '../../../../i18n/i18n';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkbox-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../../assets/icons/checkbox-checked-icon.svg';
import { ReactComponent as CheckboxCrossedIcon } from '../../../../assets/icons/checkbox-crossed-icon.svg';
import { FlexRow } from '../../default-components/flex-row';

export const TestResults: FC = React.memo(() => {
    const selectedProductionTests = useSelector(selectDisplayedProductionTestRuns);
  console.log(selectedProductionTests[0])
    return (
        <Paper
            sx={{
                height: '100%',
                backgroundColor: backgroundColorPaper,
                borderRadius: '5px',
                maxHeight: '80vh',
                padding: '16px',
            }}
            elevation={0}>
            {selectedProductionTests.length !== 1 ? (
                <Typography>{localized('SelectOneRun')}</Typography>
            ) : (
                <Stack spacing={'12px'} alignItems={'flex-start'}>
                    <CheckBoxRow
                        success={selectedProductionTests[0].endStopSensorSuccess}
                        label={localized('EndStopSensor')}
                    />
                    <CheckBoxRow
                        success={selectedProductionTests[0].slackSensorSuccess}
                        label={localized('SlackSensor')}
                    />
                    <CheckBoxRow
                        success={selectedProductionTests[0].emergencyStopSuccess}
                        label={localized('EmergencyStopTested')}
                    />
                    <CheckBoxRow
                        success={selectedProductionTests[0].emergencyLowerSuccess}
                        label={localized('EmergencyLowerTested')}
                    />
                </Stack>
            )}
        </Paper>
    );
});

interface Props {
    success: boolean | undefined;
    label: string;
}

const CheckBoxRow: FC<Props> = React.memo((props) => {
    return (
        <FlexRow alignItems={'center'}>
            <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                indeterminateIcon={<CheckboxCrossedIcon />}
                checked={props.success}
                indeterminate={props.success === false}
                disableRipple
                sx={{ paddingY: 0 }}
            />
            {props.label}
        </FlexRow>
    );
});
