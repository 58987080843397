import React, { FC, useCallback, useState } from 'react';
import {
    EnhancedTable,
    EnhancedTableCell,
    EnhancedTableRow,
    getComparator,
    HeadCell,
    Order,
} from '../enhanced-table/enhanced-table';
import { localized } from '../../../../i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDisplayedProductionTestRuns,
    selectProductionTests,
} from '../../../../state/features/production-test/production-tests-slice';
import { Checkbox, TableBody } from '@mui/material';
import { IProductionTestRun } from '../../../../api/api';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkbox-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../../assets/icons/checkbox-checked-icon.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-icon.svg';
import { toggleDisplayProductionTest } from '../../../../state/features/production-test/operation';
import { LeftBorderRadius, RightBorderRadius } from '../../../../styles/styling-constants';

const headCells: HeadCell[] = [
    { id: 'include', label: <EyeIcon />, width: '20px' },
    { id: 'date', label: localized('Date') },
    { id: 'updatedBy', label: localized('Name') },
    { id: 'startTime', label: localized('Start'), width: '120px' },
    { id: 'endTime', label: localized('End'), width: '120px' },
    { id: 'm1Max', label: localized('M1Max') },
    { id: 'wasSuccess', label: localized('LoadTest') },
];

export const ProductionTestTable: FC = React.memo(() => {
    const dispatch = useDispatch();
    const productionTestRuns: any[] = useSelector(selectProductionTests);
    const displayedProductionTestRuns = useSelector(selectDisplayedProductionTestRuns);

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof any>('startTime');

    const handleCheck = useCallback(
        (productionTestRun: IProductionTestRun) => () => {
            dispatch(toggleDisplayProductionTest(productionTestRun));
        },
        [dispatch],
    );

    return (
        <EnhancedTable
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            showSpinner={false}>
            <TableBody>
                {productionTestRuns
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((productionTestRun: IProductionTestRun) => (
                        <EnhancedTableRow onClick={handleCheck(productionTestRun)} sx={{ cursor: 'pointer' }}>
                            <EnhancedTableCell sx={LeftBorderRadius}>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    disableRipple
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxCheckedIcon />}
                                    checked={displayedProductionTestRuns.some(
                                        (displayed) => displayed.id === productionTestRun.id,
                                    )}
                                />
                            </EnhancedTableCell>
                            <EnhancedTableCell>{productionTestRun.startTime.toLocaleDateString()}</EnhancedTableCell>
                            <EnhancedTableCell>{productionTestRun.updatedBy}</EnhancedTableCell>
                            <EnhancedTableCell>{productionTestRun.startTime.toLocaleTimeString()}</EnhancedTableCell>
                            <EnhancedTableCell>{productionTestRun.endTime.toLocaleTimeString()}</EnhancedTableCell>
                            <EnhancedTableCell>{productionTestRun.m1Max}</EnhancedTableCell>
                            <EnhancedTableCell sx={RightBorderRadius}>
                                {productionTestRun.wasSuccess ? 'Success' : 'Fail'}
                            </EnhancedTableCell>
                        </EnhancedTableRow>
                    ))}
            </TableBody>
        </EnhancedTable>
    );
});
