import React, { FC } from 'react';
import { Message, ValidationRule } from 'react-hook-form';
import { CheckboxInput } from '../../default-components/form/checkbox-input';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkbox-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../../assets/icons/checkbox-checked-icon.svg';
import { ReactComponent as CheckboxCrossedIcon } from '../../../../assets/icons/checkbox-crossed-icon.svg';
import { CheckboxProps } from '@mui/material';

interface Props extends CheckboxProps {
    name: string;
    label?: string;
    disabled?: boolean;
    validators?: Partial<{
        required: Message | ValidationRule<boolean>;
        disabled: boolean;
    }>;
}

export const EtacCheckbox: FC<Props> = React.memo((props) => {
    return (
        <CheckboxInput
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            indeterminateIcon={<CheckboxCrossedIcon />}
            {...props}
        />
    );
});
