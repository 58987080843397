import { Box, Button, Dialog, DialogTitle, IconButton, Stack, SxProps } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDeviceDetailsDto } from '../../../../api/api';
import { ReactComponent as GraphIcon } from '../../../../assets/icons/graph-icon.svg';
import { ReactComponent as HistoryIcon } from '../../../../assets/icons/history-icon.svg';
import { ReactComponent as SnapshotLogIcon } from '../../../../assets/icons/snapshot-log-icon.svg';
import { localized } from '../../../../i18n/i18n';
import { routes } from '../../../../state/routes';
import { iconBackgroundGrey } from '../../../../styles/color-constants';
import { DeviceDetailsDealerAndFacility } from './device-details-dealer-and-facility';
import { DeviceDetailsFields } from './device-details-fields';
import { RemoveDeviceButton } from './remove-device-button';
import { useAuth } from '../../../../auth/auth-provider';
import { ProtectedComponent } from '../../../../auth/protected-component';
import { useSelector } from 'react-redux';
import { selectCurrentUserPermissions } from '../../../../state/features/users/users-slice';

interface Props {
    open: boolean;
    onClose: () => void;
    device: IDeviceDetailsDto | undefined;
}

export const deviceDetailsStyles: { [key: string]: SxProps } = {
    button: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '600',
    },
    img: {
        maxHeight: '140px',
        width: 'auto',
        border: `1px solid ${iconBackgroundGrey}`,
        borderRadius: '5px',
    },
    closeButton: {
        position: 'absolute',
        right: '15px',
        top: '20px',
    },
};

export const DeviceDetailsDialog: FC<Props> = React.memo((props) => {
    const { open, onClose, device } = props;
    const { isAdmin, isGlobalAdmin } = useAuth();
    const navigate = useNavigate();

    const currentUserPermissions = useSelector(selectCurrentUserPermissions);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const showServiceHistory = useCallback(() => {
        if (device?.serialNumber === undefined) return;

        navigate(routes.devicesServiceHistory + `/${device.serialNumber}`);
    }, [navigate, device]);

    const showSnapshotLog = useCallback(() => {
        if (device?.serialNumber === undefined) return;

        navigate(routes.devicesSnapshotLog + `/${device.serialNumber}`);
    }, [navigate, device]);

    const showProductionTests = useCallback(() => {
        if (device?.serialNumber === undefined) return;

        navigate(routes.devicesProductionTests + `/${device.serialNumber}`);
    }, [navigate, device]);

    const showDeviceErrors = useCallback(() => {
        if (device?.serialNumber === undefined) return;

        navigate(routes.deviceErrors + `/${device.serialNumber}`);
    }, [navigate, device]);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { padding: '20px', maxWidth: '2000px' } }}>
            <DialogTitle sx={{ fontSize: '24px', marginTop: '-12px', justifyContent: 'center', display: 'flex' }}>
                {localized('DeviceDetailsHeader')}
                <IconButton disableRipple sx={deviceDetailsStyles.closeButton} onClick={onClose}>
                    X
                </IconButton>
            </DialogTitle>
            {device && (
                <Stack direction={'row'} spacing={'40px'}>
                    <Stack>
                        <Stack direction={'row'} spacing={'40px'} marginBottom={'20px'}>
                            <Box component={'img'} src={`/images/${device?.image}`} sx={deviceDetailsStyles.img} />
                            <DeviceDetailsFields device={device} />
                        </Stack>
                        <Stack direction={'row'} marginBottom={'20px'}>
                            <DeviceDetailsDealerAndFacility device={device} closeOuterDialog={onClose} />
                        </Stack>
                    </Stack>
                    <Stack alignItems={'end'} width={'200px'}>
                        <ProtectedComponent isAllowed={isGlobalAdmin || currentUserPermissions.canUseProductionTool}>
                            <Button
                                variant={'text'}
                                sx={deviceDetailsStyles.button}
                                endIcon={<GraphIcon />}
                                onClick={showProductionTests}>
                                {localized('ProductionTests')}
                            </Button>
                        </ProtectedComponent>
                        <Button
                            variant={'text'}
                            sx={deviceDetailsStyles.button}
                            endIcon={<HistoryIcon />}
                            onClick={showServiceHistory}>
                            {localized('ServiceHistory')}
                        </Button>
                        <Button
                            variant={'text'}
                            sx={{ ...deviceDetailsStyles.button, marginTop: '36px' }}
                            endIcon={<SnapshotLogIcon />}
                            onClick={showSnapshotLog}>
                            {localized('ViewSnapshotLog')}
                        </Button>
                        {isAdmin && (
                            <RemoveDeviceButton closeParentDialog={onClose} deviceSerialNumber={device.serialNumber} />
                        )}
                    </Stack>
                </Stack>
            )}
        </Dialog>
    );
});
