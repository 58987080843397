import React, { FC } from 'react';
import { backgroundColorPaper, headerColor, primaryColor } from '../../../../styles/color-constants';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDisplayedProductionTestRuns } from '../../../../state/features/production-test/production-tests-slice';
import { localized } from '../../../../i18n/i18n';

const yCurrentLabel = {
    value: localized('M1CurrentLabel'),
    angle: -90,
    position: 'left',
};

const xLabel = {
    value: localized('XAxisLabel'),
    position: 'bottom',
};

const chartMargins = { left: 30, right: 30, bottom: 30, top: 30 };

export const CurrentGraph: FC = React.memo(() => {
    const displayedProductionTestRuns = useSelector(selectDisplayedProductionTestRuns);

    return (
        <Paper
            sx={{ height: '100%', backgroundColor: backgroundColorPaper, borderRadius: '5px', maxHeight: '80vh' }}
            elevation={0}>
            <ResponsiveContainer height={'99%'}>
                <LineChart margin={chartMargins}>
                    {displayedProductionTestRuns.map((testRun) => {
                        return (
                            <Line
                                dataKey="m1Current"
                                data={testRun.productionTestDatas}
                                key={testRun.id}
                                stroke={testRun.wasSuccess ? primaryColor : headerColor}
                                strokeWidth={'3px'}
                            />
                        );
                    })}
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="m1Current" padding={{ top: 100 }} label={yCurrentLabel} />
                    <XAxis allowDuplicatedCategory={false} dataKey="msSinceStart" label={xLabel} />
                    <Tooltip />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
});
